import { rgba } from 'polished'
import * as colors from './colors'
import { responsiveStyles } from './util'

import './fonts'

// Place global Typography in this file
export const primaryFont = `Basis, -apple-system, sans-serif`
export const secondaryFont = `Sweet Sans, -apple-system, sans-serif`
export const serifFont = `Canela, -apple-system, serif`
export const medium = 600
export const bold = 600

// styleguide B1
export const bodyLarge = `
	${ responsiveStyles('font-size', 24, 20, 20, 18) }
	line-height: 1.5em;
	font-family: ${ primaryFont };
	letter-spacing: 0;
	text-transform: none;
	font-weight: normal;
`

// styleguide B2
export const bodyMedium = `
	${ responsiveStyles('font-size', 20, 16, 16, 16) }
	line-height: 2em;
	font-family: ${ primaryFont };
	letter-spacing: 0.01em;
	text-transform: none;
	font-weight: normal;
`

// styleguide B1
export const body = `
	${ responsiveStyles('font-size', 24, 20, 20, 18) }
	line-height: 1.6em;
	font-family: ${ primaryFont };
	letter-spacing: 0.01em;
	text-transform: none;
	font-weight: normal;
`

// styleguide B2 Serif
export const bodySmall = `
	${ responsiveStyles('font-size', 20, 16, 16, 16) }
	line-height: 1.6em;
	font-family: ${ serifFont };
	letter-spacing: 0.025em;
	text-transform: none;
	font-weight: normal;
`

// styleguide Nav/H1
export const h1 = `
	${ responsiveStyles('font-size', 14, 11, 11, 11) }
	line-height: 1.25em;
	font-family: ${ secondaryFont };
	// font-weight: ${ bold };
	letter-spacing: 0.15em;
	text-transform: uppercase;
`

// styleguide H2 Sans
export const h2 = `
	${ responsiveStyles('font-size', 24, 20, 20, 18) }
	line-height: 1.25em;
	font-family: ${ primaryFont };
	// font-weight: ${ bold };
	letter-spacing: 0.01em;
	text-transform: capitalize;
`

// styleguide H2 Serif
export const h3 = `
	${ responsiveStyles('font-size', 24, 20, 20, 18) }
	line-height: 1.25em;
	font-family: ${ serifFont };
	// font-weight: ${ bold };
	letter-spacing: 0.025em;
	text-transform: none;
`

export const h4 = `
	${ h2 }
`

export const h5 = `
	${ h2 }
`

// styleguide FOOTER
export const h6 = `
	${ responsiveStyles('font-size', 9, 9, 9, 8) }
	font-family: ${ secondaryFont };
	// font-weight: ${ bold };
	line-height: 1.25em;
	letter-spacing: .15em;
	text-transform: uppercase;
`

export const blockquote = `
	${ bodyLarge }
	font-style: normal;
`

export const eyebrow = `
	${ h1 }
`

export const buttonStyle = `
	${ body }
	${ responsiveStyles('font-size', 17, 14, 14, 13) }
	line-height: 1em;
`

export const storyNotes = `
	max-width: 750px;
	p {
		code {
			background: ${ rgba(colors.textColor, 0.1) };
			color: ${ colors.textColor };
			border-radius: 3px;
			padding: .05em .35em .15em;
			font-style: normal;
		}
	}
`
