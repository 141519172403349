import MaterialIconsWoff from '../assets/fonts/icons/material-icons-regular.woff'
import MaterialIconsWoff2 from '../assets/fonts/icons/material-icons-regular.woff2'

import SweetSansWoff from '../assets/fonts/sweet-sans/SweetSans-Regular.woff'
import SweetSansWoff2 from '../assets/fonts/sweet-sans/SweetSans-Regular.woff2'

import CanelaWoff from '../assets/fonts/canela-thin/Canela-Thin.woff'
import CanelaWoff2 from '../assets/fonts/canela-thin/Canela-Thin.woff2'

import BasisWoff from '../assets/fonts/basis-grotesque/BasisGrotesquePro-Light.woff'
import BasisWoff2 from '../assets/fonts/basis-grotesque/BasisGrotesquePro-Light.woff2'


export const fontFace = (fontName, woff, woff2, fontWeight = 'normal', fontStyle = 'normal') => `
	@font-face {
		font-family: '${ fontName }';
		src:  url('${ woff }') format('woff'),
					url('${ woff2 }') format('woff2');
		font-weight: ${ fontWeight };
		font-style: ${ fontStyle };
		font-display: swap;
	}
`
export const MaterialIcons = 'Material Icons'
export const MaterialIconsFont = fontFace(MaterialIcons, MaterialIconsWoff, MaterialIconsWoff2)

export const SweetSans = 'Sweet Sans'
export const SweetSansFont = fontFace(SweetSans, SweetSansWoff, SweetSansWoff2)

export const Canela = 'Canela'
export const CanelaFont = fontFace(Canela, CanelaWoff, CanelaWoff2)

export const Basis = 'Basis'
export const BasisFont = fontFace(Basis, BasisWoff, BasisWoff2)

